import { ButtonGroup, Typography } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import { Button } from 'components/button/button'
import { Spacer } from 'components/layout/spacer'
import { AnswerOption } from 'types/cfe_assessment'
import { colors } from '../../../../../../constants'

interface AssessmentQuestionInterface {
	disabled?: boolean
	title: string
	question_number: string | number
	options: AnswerOption[]
	onClick: (optionId: number) => void
	answer_id?: number
}

export const AssessmentQuestion: React.FC<AssessmentQuestionInterface> = (props: AssessmentQuestionInterface) => {
	const getButtonVariant = (option: AnswerOption) => {
		if (!props.answer_id) {
			return 'outlined'
		}
		if (props.answer_id === option.id) {
			return 'contained'
		}
		return 'text'
	}

	const isSelected = (option: AnswerOption) => {
		return props.answer_id === option.id
	}

	return (
		<Box>
			<Typography variant='h5'>Question {props.question_number}</Typography>
			<Spacer height={theme.spacing(1)} />
			<Typography variant='body1'>{props.title}</Typography>
			<Spacer height={theme.spacing(2)} />
			<Box>
				<ButtonGroup fullWidth>
					{props.options.map((option) => {
						return (
							<Button
								disabled={props.disabled}
								key={option.id}
								id={`quiz-answer-button-${option.id}`}
								onClick={() => props.onClick(option.id)}
								variant={getButtonVariant(option)}>
								<Typography
									color={isSelected(option) ? colors.bgSurface : colors.text}
									fontWeight={props.answer_id === option.id ? 700 : 400}>
									{option.text}
								</Typography>
							</Button>
						)
					})}
				</ButtonGroup>
			</Box>
		</Box>
	)
}
